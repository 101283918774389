.public-viewing-page {
  padding: 20px;
}

h1 {
  margin-top: 0;
}

.meeting-select-container {
  margin-top: 20px;
}

.select-meeting {
  padding: 10px;
  font-size: 16px;
}

.vertical-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.voting-item, .results {
  width: 100%;
}

.logout-button-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.logout-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #0056b3;
}

.spacer {
  margin-bottom: 40px; /* Adjust this value to control the space */
}

/* Primary Button */
.btn-primary {
    background-color: #0d6efd;
    color: #ffffff;
    border-color: #0d6efd;
}

/* Danger Button */
.btn-danger {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
}

/* Small Danger Button */
.btn-danger.btn-sm {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/* Secondary Button */
.btn-secondary {
    background-color: #6c757d;
    color: #ffffff;
    border-color: #6c757d;
}

/* Info Button */
.btn-info {
    background-color: #0dcaf0;
    color: #ffffff;
    border-color: #0dcaf0;
}


  /* .public-viewing-page {
  text-align: center;
  font-family: Arial, sans-serif;
}

.content {
  display: flex;
  justify-content: space-between;
}

.left-half, .right-half {
  width: 45%;
}

.left-half h4, .right-half h4 {
  margin-top: 0;
}
 */