.run-meeting-page {
  padding: 20px;
}

h1, h2, h3 {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.input-group {
  display: flex;
}

.input-group .form-control {
  flex-grow: 1;
}

.input-group-append {
  margin-left: 10px;
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-secondary {
  margin-right: 10px;
}

.agenda-section {
  margin-top: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.agenda-section h2 {
  margin-bottom: 15px;
}

.agenda-section .btn {
  margin-top: 10px;
}

.prominent-outline {
  border: 2px solid #007bff;
  padding: 10px;
}

form {
  margin-top: 30px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form h3 {
  margin-bottom: 20px;
}

form .form-group {
  margin-bottom: 15px;
}

form .form-control {
  margin-bottom: 10px;
}

form .btn-secondary {
  margin-top: 10px;
}

form .btn-primary {
  display: block;
  margin-top: 20px;
}

.run-meeting-page .attendees-count {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.choice-container {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: flex-start; /* Aligns choice input and button to the left */
  margin-bottom: 20px;
}

.choice-container .form-control {
  width: 100%; /* Make the choice input take the full width */
  margin-bottom: 10px; /* Add space between the input and the button */
}

.choice-text-and-button {
  width: 100%; /* Ensure full width for the button */
}

.bio-subform {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Ensures the subform takes up the full width available */
}

.bio-subform .ql-container {
  width: 100%; /* Ensure the text editor takes up the full width */
}

.bio-subform .btn-secondary {
  display: block;
  margin: 10px auto; /* Center the upload button */
}

/* Primary Button */
.btn-primary {
    background-color: #0d6efd;
    color: #ffffff;
    border-color: #0d6efd;
}

/* Danger Button */
.btn-danger {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
}

/* Small Danger Button */
.btn-danger.btn-sm {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/* Secondary Button */
.btn-secondary {
    background-color: #6c757d;
    color: #ffffff;
    border-color: #6c757d;
}

/* Info Button */
.btn-info {
    background-color: #0dcaf0;
    color: #ffffff;
    border-color: #0dcaf0;
}

