/* App.css */
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
}

.App-nav {
  background-color: #f0f0f0;
  padding: 10px;
}

.App-nav ul {
  list-style: none;
  padding: 0;
}

.App-nav ul li {
  display: inline;
  margin: 0 10px;
}

.App-nav ul li a {
  text-decoration: none;
  color: #282c34;
}

.App-nav ul li a:hover {
  text-decoration: underline;
}

.App-main {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.main-content {
  display: flex;
  width: 100%;
  max-width: 1200px;
}

.column {
  flex: 1;
  padding: 10px;
}

.column-left {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  margin-right: 10px;
}

.column-right {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

/*.form-control {
  width: 100%;
  padding: 10px;
  margin: 5px 0 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
*/

.btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin: 10px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn:hover {
  background-color: #45a049;
}

.list-group {
  list-style: none;
  padding: 0;
}

.list-group-item {
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
  border-radius: 4px;
}

.list-group-item .btn {
  margin-left: 10px;
}

.login-container {
  background-color: #f5f5f5; /* Change this to your desired background shade */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.bi {
  font-size: 1.25rem;
  line-height: 0;
}

.list-group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-left {
  text-align: left;
}

.list-group-item {
  display: flex;
  align-items: center;
}

.img-thumbnail {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.flex-grow-1 {
  flex-grow: 1;
  text-align: left;
}

.bio-label {
  margin-left: 10px;
  text-align: left;
}

/* Center align the title and description */
.title-description-center {
  text-align: center;
}

/* Align choice text to the left */
.choice-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Aligns text to the left */
}

.order-buttons-container {
  margin-right: 20px; /* Adjust the space between order buttons and choices */
}

.question-container {
  text-align: left;
}

.question-category {
  font-weight: bold;
}

.choice-text-container {
  text-align: left;
}

.button-container {
  text-align: center;
}

.choice-text-bio .flex-grow-1 {
  margin-right: 10px; /* Keep margin for spacing */
  text-align: left; /* Ensure text is left-aligned */
}

.choice-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Spacing between choices */
}

.choice-checkbox {
  margin-right: 10px; /* Add space between checkbox and choice text */
}

.text-center {
  text-align: center;
}

.list-group-item .text-center {
  flex-grow: 1;
  text-align: center;
}

/*.form-group {
  text-align: center;
}
*/

.btn-group {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* Primary Button */
.btn-primary {
    background-color: #0d6efd;
    color: #ffffff;
    border-color: #0d6efd;
}

/* Danger Button */
.btn-danger {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
}

/* Small Danger Button */
.btn-danger.btn-sm {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/* Secondary Button */
.btn-secondary {
    background-color: #6c757d;
    color: #ffffff;
    border-color: #6c757d;
}

/* Info Button */
.btn-info {
    background-color: #0dcaf0;
    color: #ffffff;
    border-color: #0dcaf0;
}
/*_______________________________________________________*/

/* Ensure buttons use the correct Bootstrap colors and styles */

/* Form group styling for bio editing */

.bio-editor-container {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    background-color: #f8f9fa;
}

.flex-grow-1 {
  flex-grow: 1;
  text-align: left;
  margin-right: 10px;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

/* Utility classes for margin and flex */
.mr-2 {
  margin-right: 0.5rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.d-flex {
    display: flex;
    align-items: center;
}

.align-items-center {
  align-items: center !important;
}