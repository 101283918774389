.voting-page {
  padding: 20px;
  text-align: center;
}

h1, h2 {
  margin-top: 0;
}

.voting-options {
  margin-top: 20px;
  display: inline-block;
  text-align: left;
}

.voting-options label {
  display: block;
  margin-bottom: 10px;
}

.voting-options input[type="radio"] {
  margin-right: 10px;
}

.vote-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.vote-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.logout-button-container {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.logout-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #0056b3;
}

.error-page {
  padding: 20px;
  text-align: center;
}

.retry-button {
  padding: 10px 20px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.retry-button:hover {
  background-color: #cc0000;
}

.prominent-outline {
  border: 2px solid #007bff;
  padding: 10px;
}

.candidate-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px; /* Add space between choices */
  padding: 10px; /* Add padding for better separation */
  border: 1px solid #ccc; /* Optional: Add border for visual separation */
  border-radius: 5px; /* Optional: Add border radius for better aesthetics */
}

.view-bio-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #28a745; /* Change to green */
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.bio-view-page {
  text-align: center;
  padding: 20px;
}

.candidate-picture {
  width: 50%;
  max-width: 50%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.candidate-name {
  font-weight: bold;
  margin-top: 10px;
}

.candidate-bio {
  margin-top: 20px;
  text-align: left;
}

.return-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}