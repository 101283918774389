/* MemberActivation.css */
.member-activation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.member-activation-header {
  margin-bottom: 20px;
}

.logout-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.logout-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #0056b3;
}


.select-meeting {
  margin-bottom: 20px;
  width: 100%;
}

.search-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-section input {
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.search-section button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.search-section button:hover {
  background-color: #0056b3;
}

.search-results {
  list-style-type: none;
  padding: 0;
  width: 100%;
}

.search-results li {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: white;
  margin-bottom: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.activation-message {
  margin-top: 20px;
  color: green;
}

.error-message {
  color: red;
}

/* Primary Button */
.btn-primary {
    background-color: #0d6efd;
    color: #ffffff;
    border-color: #0d6efd;
}

/* Danger Button */
.btn-danger {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
}

/* Small Danger Button */
.btn-danger.btn-sm {
    background-color: #dc3545;
    color: #ffffff;
    border-color: #dc3545;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/* Secondary Button */
.btn-secondary {
    background-color: #6c757d;
    color: #ffffff;
    border-color: #6c757d;
}

/* Info Button */
.btn-info {
    background-color: #0dcaf0;
    color: #ffffff;
    border-color: #0dcaf0;
}
