.tenant-registration {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.tenant-registration h2 {
  text-align: center;
  margin-bottom: 20px;
}

.tenant-registration .form-group {
  margin-bottom: 15px;
}

.tenant-registration label {
  display: block;
  margin-bottom: 5px;
}

.tenant-registration input,
.tenant-registration select {
  width: 100%;
  padding: 8px;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.tenant-registration button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tenant-registration button:disabled {
  background-color: #ccc;
}

.success-page, .cancel-page {
  text-align: center;
  padding: 20px;
}

.success-page p, .cancel-page p {
  font-size: 1.2em;
}
